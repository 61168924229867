import {
  Box,
  Button,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import { modalStyle } from 'src/utils/history';
import { handleIconNameChange, uploadAssets } from 'src/views/dashboard/DashboardFunctions'

const UploadModal = ({
  uploadThreshold,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbar,
  getAccessTokenSilently,
  setUploading,
  setShowUpload,
  showUpload,
  uploading,
  handleUpgrade,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [iconNames, setIconNames] = useState([]);
  const [urls, setUrls] = useState([]); // Array to store URLs with icon names
  const [urlIconNames, setUrlIconNames] = useState([]); // Array to store Icon Names
  const [urlInput, setUrlInput] = useState(''); // Current URL input
  const [iconNameInput, setIconNameInput] = useState(''); // Current icon name input

  useEffect(() => {
    if (!showUpload) {
      setSelectedFiles([]);
      setIconNames([]);
      setUrls([]);
      setUrlInput('');
      setIconNameInput('');
    }
  }, [showUpload]);

  const handleFileChange = (acceptedFiles) => {
    const pngFiles = acceptedFiles.filter(
      (file) =>
        file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg',
    );
    setSelectedFiles((prevFiles) => [...prevFiles, ...pngFiles]);
  };

  const handleAddUrl = () => {
    if (urlInput.trim() && iconNameInput.trim()) {
      setUrls((prevUrls) => [...prevUrls, urlInput.trim()]); // Add URL
      setUrlIconNames((prevIconNames) => [...prevIconNames, iconNameInput.trim()]); // Add Icon Name
      setUrlInput('');
      setIconNameInput('');
    }
  };

  const handleRemoveUrl = (index) => {
    setUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
    setUrlIconNames((prevIconNames) => prevIconNames.filter((_, i) => i !== index));
  };

  const renderUrlInputs = () => (
    <>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <TextField
          fullWidth
          placeholder="Enter URL"
          size="small"
          value={urlInput}
          onChange={(e) => setUrlInput(e.target.value)}
        />
        <TextField
          fullWidth
          placeholder="Enter Icon Name"
          size="small"
          value={iconNameInput}
          onChange={(e) => setIconNameInput(e.target.value)}
        />
        <Button
          size="small"
          variant="contained"
          onClick={handleAddUrl}
          disabled={!urlInput.trim() || !iconNameInput.trim()}
        >
          Add
        </Button>
      </div>
      <ul>
        {urls.map((url, index) => (
          <li
            key={index}
            style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}
          >
            <Typography style={{ flexGrow: 1 }}>
              {urlIconNames[index]} - {url}
            </Typography>
            <Button
              size="small"
              color="error"
              onClick={() => handleRemoveUrl(index)}
            >
              <DeleteIcon fontSize="small" />
            </Button>
          </li>
        ))}
      </ul>
    </>
  );

  const renderPreview = () => {
    if (!selectedFiles.length) return <p>No files selected.</p>;

    return (
      <ul>
        {selectedFiles.map((file, index) => (
          <div key={file.name}>
            <div className="input-see">
              <TextField
                hiddenLabel
                placeholder="Icon name *"
                onChange={(e) =>
                  handleIconNameChange(index, e.target.value, iconNames, setIconNames)
                }
                size="small"
                sx={{
                  '& .MuiInputBase-input': {
                    color: 'black',
                  },
                }}
              />
              <img
                className="img"
                src={URL.createObjectURL(file)}
                alt={file.name}
                width="75"
                height="75"
              />
              <Button
                className="icon-button"
                type="button"
                onClick={() => setSelectedFiles(selectedFiles.filter((f) => f !== file))}
              >
                <DeleteIcon fontSize="small" />
              </Button>
            </div>
          </div>
        ))}
      </ul>
    );
  };

  async function upload() {
    if (uploadThreshold === 0) {
      setSnackbarMessage('Please Upgrade for adding more Assets');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }
    const token = await getAccessTokenSilently();
    const payload = {
      files: selectedFiles,
      iconNames: iconNames,
      url: urls,
      urlIconNames: urlIconNames
    };
    
    uploadAssets(token, payload, setUploading, (response) => {
      setSelectedFiles([]);
      setIconNames([]);
      setUrls([]);
      setUploading(false);
      setShowUpload(false);

      if (response.data.error) {
        setSnackbarMessage('Upload Failed');
        setSnackbarSeverity('error');
      } else {
        setSnackbarMessage('Assets Uploaded Successfully');
        setSnackbarSeverity('success');
      }
      setOpenSnackbar(true);
    });
  }

  return (
    <Modal open={showUpload} onClose={() => setShowUpload(false)}>
      <Box
        sx={{
          ...modalStyle,
          width: '600px', // Adjust modal width
          height: 'auto', // Adjust modal height
        }}
      >
        <Typography variant="h6">Upload Your Asset</Typography>
        <hr />
        {uploadThreshold !== 0 ? (
          <div>
            <Typography variant="h6">1. Image Upload</Typography>
            <Dropzone onDrop={handleFileChange}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  <Button>Click to Select</Button>
                  <p>(Only png, jpg or jpeg supported)</p>
                </div>
              )}
            </Dropzone>
            {renderPreview()}

            <Typography variant="h6" style={{ marginTop: '20px' }}>
              2. Image URL (Optional)
            </Typography>
            {renderUrlInputs()}
          </div>
        ) : (
          <Typography>
            Oops! You've reached the 10-icon upload limit. Upgrade to Premium for unlimited uploads and exclusive features!
          </Typography>
        )}
        <hr />
        <footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography>Remaining Upload: {uploadThreshold}</Typography>
          <div>
            {!uploading && uploadThreshold > 0 && (
              <Button variant="contained" onClick={upload}>
                Upload
              </Button>
            )}
            {uploading && (
              <Button color="error" disabled>
                <CircularProgress size="small" />
                Uploading...
              </Button>
            )}
            {uploadThreshold <= 0 && (
              <Button color="warning" onClick={handleUpgrade}>
                Upgrade
              </Button>
            )}
          </div>
        </footer>
      </Box>
    </Modal>
  );
};

export default UploadModal;
