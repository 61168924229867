import axios from 'axios';
import { CONSTANTS } from '../Constants';
import YAML from 'yaml';

axios.defaults.withCredentials = true;

function areParenthesesBalanced(input) {
    const stack = [];
    const opening = ['(', '[', '{'];
    const closing = [')', ']', '}'];

    for (let char of input) {
        if (opening.includes(char)) {
            stack.push(char);
        } else if (closing.includes(char)) {
            const lastOpening = stack.pop();
            const correspondingOpening = opening[closing.indexOf(char)];

            if (lastOpening !== correspondingOpening) {
                return false; 
            }
        }
    }

    return stack.length === 0; 
}

export async function generateUML(
    reqData,
    isDownload,
    mode,
    setImageSrc,
    setSnackbarMessage,
    setSnackbarSeverity,
    setOpenSnackbar,
    setVisibleLoading,
    setOpenSnackbarDiagram
) {
    setOpenSnackbarDiagram(true);
    
    if (!sessionStorage.getItem('ipAddress')) {
        const userAgent = navigator.userAgent;
        const response = await fetch('https://api64.ipify.org?format=json');
        const respJson = await response.json();
        sessionStorage.setItem('ipAddress', respJson.ip);
        sessionStorage.setItem('userAgent', userAgent);
    }

    const data = {
        ipAddress: sessionStorage.getItem('ipAddress'),
        userAgent: sessionStorage.getItem('userAgent'),
    };

    let url;
    let isValidFormat = true;

    
    if (mode === CONSTANTS.JSON) {
      
        isValidFormat = areParenthesesBalanced(reqData);
        data.json = reqData;
        url = "/api/diagram/uml/json/convert";
    } else if (mode === CONSTANTS.YAML) {
        data.yaml = reqData;
        
        url = "/api/diagram/uml/yaml/convert";
    } else if (mode === CONSTANTS.ASCII) {
      
        // Validate and handle Mermaid ASCII
        // if (!reqData.trim()) {
        //     setSnackbarMessage('Mermaid ASCII input cannot be empty');
        //     setSnackbarSeverity('error');
        //     setOpenSnackbar(true);
        //     setVisibleLoading(false);
        //     return;
        // }
        data.ascii = reqData;  
      
        url = "/api/diagram/uml/ascii/convert";
    }

    if (!isValidFormat) {
        setSnackbarMessage('Invalid JSON/YAML format');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        setVisibleLoading(false);
        return;
    }

    let downloadQuery = "?isDownload=false";
    if (isDownload) {
        downloadQuery = "?isDownload=true";
    }

    
    axios
        .post(`${process.env.REACT_APP_SIDEBAR_API_URL + url + downloadQuery}`, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((resp) => {
            setOpenSnackbarDiagram(false);
            setVisibleLoading(false);
            if (resp && resp.data.result.length > 0 && resp.data.result[0] === "200") {
                setImageSrc(resp.data.result[1]);

                
                if (mode === CONSTANTS.JSON) {
                    localStorage.setItem('LASTGENERATEDIMAGE_JSON', resp.data.result[1]);
                    localStorage.setItem('EDITORSTATE_JSON', reqData);
                } else if (mode === CONSTANTS.YAML) {
                    localStorage.setItem('LASTGENERATEDIMAGE_YAML', resp.data.result[1]);
                    localStorage.setItem('EDITORSTATE_YAML', reqData);
                } else if (mode === CONSTANTS.ASCII) {
                    localStorage.setItem('LASTGENERATEDIMAGE_ASCII', resp.data.result[1]);
                    localStorage.setItem('EDITORSTATE_ASCII', reqData);
                }
            } else {
                setSnackbarMessage('Internal Server error');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            }
        })
        .catch((error) => {
            setVisibleLoading(false);
            setSnackbarMessage('Internal Server error');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        });
}
