import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { iconCode } from 'src/redux/actions/iconCode'
import { COLORS } from './Colors'
import { CONSTANTS } from './Constants'
import { useAuth0 } from '@auth0/auth0-react'
import ReplayIcon from '@mui/icons-material/Replay'
import { handleDiagramUrl } from 'src/views/dashboard/DashboardFunctions'
import { setSelectedMode } from 'src/redux/actions/StateAction'
import { Button, Tooltip } from '@mui/material'

export const AppSidebarNav = ({ items }) => {
  const dispatch = useDispatch()
  const [uploadLimit, setUploadLimit] = useState(50)
  const [myIconsList, setMyIconsList] = useState([])
  const [websequenceExample, setWebsequenceExample] = useState([])
  const [flowchartExample, setFlowchartExample] = useState([])
  const [swaggerExample, setSwaggerExample] = useState([])
  const [jsonUmlExample, setJsonUmlExample] = useState([])
  const [yamlUmlExample, setYamlUmlExample] = useState([])
  const [classDiagramExample, setClassDiagramExample] = useState([])
  const [brainStormExample, setBrainStormExample] = useState([])
  const [protobufExample, setProtobufExample] = useState([])
  const [dockerComposeExample, setDockerComposeExample] = useState([])
  const [avroSchemaExample, setAvroSchemaExample] = useState([])
  const [d2Example, setD2Example] = useState([])
  const [archGoExample, setArchGoExample] = useState([])
  const [mindmapExample, setMindMapExample] = useState([])
  const [terraformExample, setTerraformExample] = useState([])
  const [mathEquationExample, setMathEquationExample] = useState([])
  const [playBookGrapherExample, setPlayBookGrapherExample] = useState([])
  const [inventoryGrapherExample, setInventoryGrapherExample] = useState([])
  const [prevCode, setPrevCode] = useState(null)
  const [asciiExample, setAsciiExamples]= useState([])

  let selectedMode = useSelector((state) => state.stateReducer.selectModeValue)

  const { getAccessTokenSilently, isAuthenticated } = useAuth0()
  axios.defaults.withCredentials = true

  async function getMyIcons(isClear) {
    if (isClear) setMyIconsList([])
    var userId = sessionStorage.getItem('userFetchedId')
    if (userId != null) {
      userId = userId.replace('|', '%7C')
    }else{
      return;
    }

    const token = await getAccessTokenSilently()
    if(!token) return
    axios
      .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/userasset/get?userId=` + userId, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        sessionStorage.removeItem('ICONCODE')
        sessionStorage.setItem('MYICONLENGTH', '0')
        if (response.data.result) {
          setMyIconsList(response.data.result)
          sessionStorage.setItem('MYICONLENGTH', response.data.result.length)
        }
      })
      .catch((err) => {
        console.error(err.Message)
      })
  }

  async function getJsonData(type, state, setState, localStorageKey) {
    
    if (state.length > 0) return
    else if (localStorage.getItem(localStorageKey)) {
      var parsedJSON = JSON.parse(localStorage.getItem(localStorageKey))
      setState(parsedJSON)
      return
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_SIDEBAR_API_URL}/${type}.json`, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store'
        },
      })
      
      setState(response.data)
      localStorage.setItem(localStorageKey, JSON.stringify(response.data))
    } catch (err) {
      console.log(err.message)
    }
  }

  async function getWebSequenceJson() {
    await getJsonData('websequence', websequenceExample, setWebsequenceExample, 'websequenceJSON')
  }

  async function getFlowchartJson() {
    await getJsonData('flowchart', flowchartExample, setFlowchartExample, 'flowchartJSON')
  }

  async function getSwaggerJson() {
    await getJsonData('swagger', swaggerExample, setSwaggerExample, 'swaggerJSON')
  }

  async function getJsonUml() {
    await getJsonData('jsonUml', jsonUmlExample, setJsonUmlExample, 'jsonUmlJSON')
  }

  async function getYamlUml() {
    await getJsonData('yamlUml', yamlUmlExample, setYamlUmlExample, 'yamlUmlJSON')
  }

  
  async function getAscii() {
    await getJsonData('ascii', asciiExample, setAsciiExamples, 'asciiJSON')
  }

  async function getClassDiagramJson() {
    await getJsonData(
      'classDiagram',
      classDiagramExample,
      setClassDiagramExample,
      'classDiagramJSON',
    )
  }

  async function getBrainstormJson() {
    await getJsonData('brainStorm', brainStormExample, setBrainStormExample, 'brainStormJSON')
  }

  async function getProtobufJson() {
    await getJsonData('protobuf', protobufExample, setProtobufExample, 'protobufJSON')
  }

  async function getDockerComposeJson() {
    await getJsonData(
      'dockerCompose',
      dockerComposeExample,
      setDockerComposeExample,
      'dockerComposeJSON',
    )
  }

  async function getAvroSchemaJson() {
    await getJsonData('avro', avroSchemaExample, setAvroSchemaExample, 'avroSchemaJSON')
  }

  async function getD2Json() {
    await getJsonData('d2', d2Example, setD2Example, 'd2ExampleJSON')
  }

  async function getArchGoJson() {
    await getJsonData('archGo', archGoExample, setArchGoExample, 'archGoJSON')
  }

  async function getMindMapJson() {
    await getJsonData('mindmap', mindmapExample, setMindMapExample, 'mindmapJSON')
  }

  async function getTerraformJson() {
    await getJsonData('terraform', terraformExample, setTerraformExample, 'terraformJSON')
  }

  async function getMathEquationJson() {
    await getJsonData(
      'mathEquation',
      mathEquationExample,
      setMathEquationExample,
      'mathEquationJSON',
    )
  }

  async function getPlayBookGrapherJson() {
    await getJsonData(
      'playBookGrapher',
      playBookGrapherExample,
      setPlayBookGrapherExample,
      'playBookGrapherJSON',
    )
  }

  async function getInventoryGrapherJson() {
    await getJsonData(
    'inventoryGrapher', inventoryGrapherExample, 
    setInventoryGrapherExample, 'inventoryGrapherJson')
  }

  function fetchAllIconJson() {
    const actionMap = {
      [CONSTANTS.WEBSEQUENCE]: getWebSequenceJson,
      [CONSTANTS.FLOW_CHART]: getFlowchartJson,
      [CONSTANTS.SWAGGER]: getSwaggerJson,
      [CONSTANTS.JSON]: getJsonUml,
      [CONSTANTS.YAML]: getYamlUml,
      [CONSTANTS.ASCII]: getAscii,
      [CONSTANTS.CLASS_DIAGRAM]: getClassDiagramJson,
      [CONSTANTS.BRAINSTORM]: getBrainstormJson,
      [CONSTANTS.PROTOBUF]: getProtobufJson,
      [CONSTANTS.DOCKERCOMPOSE]: getDockerComposeJson,
      [CONSTANTS.AVROSCHEMA]: getAvroSchemaJson,
      [CONSTANTS.D2]: getD2Json,
      [CONSTANTS.ARCHGO]: getArchGoJson,
      [CONSTANTS.TERRAFORM]: getTerraformJson,
      [CONSTANTS.MATHEQUATION]: getMathEquationJson,
      [CONSTANTS.PLAYBOOK_GRAPHER]: getPlayBookGrapherJson,
      [CONSTANTS.INVENTORY_GRAPHER]: getInventoryGrapherJson,
      [CONSTANTS.MINDMAP]: getMindMapJson
    };
    for (const [key, actionFunction] of Object.entries(actionMap)) {
      actionFunction();
    }
  }

  useEffect(() => {
    handleDiagramUrl()

    if (localStorage.getItem('selectedMode') === CONSTANTS.WEBSEQUENCE) getWebSequenceJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.FLOW_CHART) getFlowchartJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.JSON) getJsonUml()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.YAML) getYamlUml()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.ASCII) getAscii()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.SWAGGER) getSwaggerJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.CLASS_DIAGRAM) getClassDiagramJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.BRAINSTORM) getBrainstormJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.PROTOBUF) getProtobufJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.DOCKERCOMPOSE)
      getDockerComposeJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.AVROSCHEMA) getAvroSchemaJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.D2) getD2Json()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.ARCHGO) getArchGoJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.TERRAFORM) getTerraformJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.MATHEQUATION) getMathEquationJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.MINDMAP) getMindMapJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.PLAYBOOK_GRAPHER)
      getPlayBookGrapherJson()
    else if (localStorage.getItem('selectedMode') === CONSTANTS.INVENTORY_GRAPHER) 
      getInventoryGrapherJson()
  }, [])
  
  useEffect(() => {
    fetchAllIconJson()
    setTimeout(getMyIcons, 1000)
  }, [])

  const handleUploadButtonClick = () => {
    const customEvent = new CustomEvent('uploadBtnClickEvent', {
      detail: { message: 'Hello from React!' },
    })

    document.dispatchEvent(customEvent)
  }

  document.addEventListener('selectedMode', function (event) {
    event.stopPropagation()
    event.preventDefault()
    if (event.detail) {
      dispatch(setSelectedMode(event.detail))
      if (event.detail === CONSTANTS.WEBSEQUENCE) {
        getWebSequenceJson()
      } else if (event.detail === CONSTANTS.FLOW_CHART) {
        getFlowchartJson()
      } else if (event.detail === CONSTANTS.SWAGGER) {
        getSwaggerJson()
      } else if (event.detail === CONSTANTS.JSON) {
        getJsonUml()
      } else if (event.detail === CONSTANTS.YAML) {
        getYamlUml()
      } else if(event.detail === CONSTANTS.ASCII){
        getAscii()
      } else if (event.detail === CONSTANTS.CLASS_DIAGRAM) {
        getClassDiagramJson()
      } else if (event.detail === CONSTANTS.BRAINSTORM) {
        getBrainstormJson()
      } else if (event.detail === CONSTANTS.PROTOBUF) {
        getProtobufJson()
      } else if (event.detail === CONSTANTS.DOCKERCOMPOSE) {
        getDockerComposeJson()
      } else if (event.detail === CONSTANTS.AVROSCHEMA) {
        getAvroSchemaJson()
      } else if (event.detail === CONSTANTS.D2) {
        getD2Json()
      } else if (event.detail === CONSTANTS.ARCHGO) {
        getArchGoJson()
      } else if (event.detail === CONSTANTS.TERRAFORM) {
        getTerraformJson()
      } else if (event.detail === CONSTANTS.MATHEQUATION) {
        getMathEquationJson()
      } else if (event.detail === CONSTANTS.PLAYBOOK_GRAPHER) {
        getPlayBookGrapherJson()
      } else if (event.detail === CONSTANTS.MATHEQUATION) {
        getMathEquationJson()
      } else if (event.detail === CONSTANTS.PLAYBOOK_GRAPHER) {
        getPlayBookGrapherJson()
      } else if (event.detail === CONSTANTS.INVENTORY_GRAPHER) {
        getInventoryGrapherJson()
      } else if (event.detail === CONSTANTS.MINDMAP) {
        getMindMapJson()
      }
    }
  })

  document.addEventListener('isSubscribed', function (event) {
    event.stopPropagation()
    event.preventDefault()
    if (event.detail) {
      if (event.detail.message === 'true') {
        setUploadLimit(200)
      } else {
        setUploadLimit(50)
      }
    }
  })

  document.addEventListener('userAssetsUploaded', function (event) {
    event.stopPropagation()
    event.preventDefault()
    if (event) {
      setTimeout(getMyIcons, 200, true)
    }
  })

  function dipatchIcon(code) {
    if (prevCode === code) {
      code = code + ' REPLACEME'
    }
    setPrevCode(code)
    dispatch(iconCode(code))
  }

  const handleDragStart = (e, code) => {
    e.dataTransfer.setData('text/plain', code)
  }

  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    let result = ''

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      result += characters.charAt(randomIndex)
    }

    return result
  }

  var i = 1
  var iconNamesTracker = []
  function getInnerItems(ele, isUserAssets) {
    if (isUserAssets) {
      var iconName = 'icon_' + i
      var imageName = 'REPLACEFOLDER/' + generateRandomString(32) + '.png'

      var existingArray = JSON.parse(sessionStorage.getItem('IMAGE_TO_BE_DELETED')) || []
      existingArray.push(imageName)
      sessionStorage.setItem('IMAGE_TO_BE_DELETED', JSON.stringify(existingArray))

      if (ele.name) {
        iconName = ele.name
        iconName = iconName.replace(/ /g, '_')
        if (/^\d+$/.test(iconName)) iconName = 'icon_' + iconName
        if (iconNamesTracker.includes(iconName)) {
          iconName = iconName + '_' + i
          i++
        }
        iconNamesTracker.push(iconName)
      }

      var imageVariable = generateRandomString(16)
      var codetobeappended =
        '\n\n' +
        iconName +
        '_url="' +
        ele.url +
        '"\n\n' +
        imageVariable +
        '="' +
        imageName +
        '" \n\nurlretrieve(' +
        iconName +
        '_url, ' +
        imageVariable +
        ')'
      var code = '\n' + iconName + '= Custom("' + iconName + '",' + iconName + '_ICON)'
      var iconObj = {
        cloudinaryUrl: ele.url,
        name: ele.name,
        code: code,
      }

      var key = iconName + '--SPLIT--' + imageVariable
      var existingIconCode = JSON.parse(sessionStorage.getItem('ICONCODE')) || {}
      existingIconCode[key] = '\n' + codetobeappended
      sessionStorage.setItem('ICONCODE', JSON.stringify(existingIconCode))

      ele = { temp: [iconObj] }
    }
    const itemNames = ele[Object.keys(ele)[0]].map((e, i) => {
      return (
        <div
          key={i}
          className="w-50 cursor-pointer"
          draggable
          onDragStart={(f) => handleDragStart(f, e.code)}
        >
          <Tooltip
            title={
              <div className="alignMiddle">
                <img className="w-100 mb-3 bg-light" alt={e.name} src={`${e.cloudinaryUrl}`} />
                <p style={{ color: 'antiquewhite' }}>{e.name}</p>
              </div>
            }
            placement="top"
          >
            <img
              className="w-50 mb-3 bg-light"
              role="button"
              src={`${e.cloudinaryUrl}`}
              onClick={() => {
                dipatchIcon(e.code)
              }}
            />
          </Tooltip>
        </div>
      )
    })
    return itemNames
  }

  function getNestedItems(id, arrayList) {
    if (!arrayList) arrayList = items
    const arr = Object.values(arrayList[id]).pop()

    return arr.map((e, i) => {
      return (
        <CAccordion key={`accordion-${id}-${i}`}>
          <CAccordionItem
            itemKey={i}
            style={{ color: COLORS.WHITE, backgroundColor: COLORS.PRIMARY_DARK }}
          >
            <CAccordionHeader style={{ paddingLeft: '5px', background: COLORS.PRIMARY_LIGHT }}>
              {Object.keys(arr[i])}
            </CAccordionHeader>
            <CAccordionBody style={{ backgroundColor: COLORS.SECONDARY }}>
              <div className="row justify-content-center align-items-center">
                {getInnerItems(e)}
              </div>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      )
    })
  }

  function getImages(arrayList, codeSuffix) {
    const arr = Object.values(arrayList).pop()

    return arr.map((e, i) => (
      selectedMode === CONSTANTS.MATHEQUATION || CONSTANTS.CLASS_DIAGRAM ? (
        <div
        key={i}
        onClick={() => {
          dipatchIcon(`\n${e.code}\n #EXAMPLE${codeSuffix}CODE`)
        }}
        draggable
        onDragStart={(f) => handleDragStart(f, e.code)}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <Tooltip
          title={
            <div className="alignMiddle">
              <img 
                alt={e.name} 
                src={`${e.cloudinaryUrl}`} 
                className="tooltip-image w-100 mb-3 bg-light" 
              />
              <p style={{ color: 'antiquewhite', marginLeft:'10px'}}>{e.name}</p>
            </div>
          }
          placement="top"
        >
          <img
            className="mb-9 bg-lightt"
            src={`${e.cloudinaryUrl}`}
            onClick={() => {
             dipatchIcon(`\n${e.code}\n #EXAMPLE${codeSuffix}CODE`)
            }}
          />
          <p
            
            style={{ marginLeft: '2px', color: 'black', cursor: 'pointer', textAlign:'center'}}
            onClick={() => {
              dipatchIcon(`\n${e.code}\n #EXAMPLE${codeSuffix}CODE`)
            }}
          >
            {e.name}
          </p>
        </Tooltip>
      </div>
      
      ) : (
        <div
          key={i}
          onClick={() => {
            dipatchIcon(`\n${e.code}\n #EXAMPLE${codeSuffix}CODE`)
          }}
          draggable
          onDragStart={(f) => handleDragStart(f, e.code)}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          <Tooltip
            title={
              <div className="alignMiddle">
                <img alt={e.name} src={`${e.cloudinaryUrl}`} className="w-100 mb-3 bg-light" />
                <p style={{ color: 'antiquewhite' }}>{e.name}</p>
              </div>
            }
            placement="top"
          >
            <img
              width={50}
              className="mb-3 bg-light"
              src={`${e.cloudinaryUrl}`}
              onClick={() => {
                dipatchIcon(`\n${e.code}\n #EXAMPLE${codeSuffix}CODE`)
              }}
            />
          </Tooltip>
          <p
            style={{ marginLeft: '10px', color: 'black', cursor: 'pointer' }}
            onClick={() => {
              dipatchIcon(`\n${e.code}\n #EXAMPLE${codeSuffix}CODE`)
            }}
          >
            {e.name}
          </p>
        </div>
      )
    ));
    
  }

  function getWebSeqImages(arrayList) {
    return getImages(arrayList, 'WEBSEQ')
  }

  function getFlowchartImages(arrayList) {
    return getImages(arrayList, 'FLOWCHART')
  }

  function getSwaggerImages(arrayList) {
    return getImages(arrayList, 'SWAGGER')
  }

  function getJSONImages(arrayList) {
    return getImages(arrayList, 'JSON')
  }

  function getYamlImages(arrayList) {
    return getImages(arrayList, 'YAML')
  }

  function getAsciiImage(arrayList){
    return getImages(arrayList, 'ASCII')
  }


  function getClassDiagramImages(arrayList) {
    return getImages(arrayList, 'CLASSDIAGRAM')
  }

  function getBrainstormImages(arrayList) {
    return getImages(arrayList, 'BRAINSTORM')
  }

  function getProtobufImages(arrayList) {
    return getImages(arrayList, 'PROTOBUF')
  }

  function getDockerComposeImages(arrayList) {
    return getImages(arrayList, 'DOCKERCOMPOSE')
  }

  function getAvroSchemaImages(arrayList) {
    return getImages(arrayList, 'AVROSCHEMA')
  }

  function getD2Images(arrayList) {
    return getImages(arrayList, 'D2')
  }

  function getArchGoImages(arrayList) {
    return getImages(arrayList, 'ARCHGO')
  }

  function getTerraformImages(arrayList) {
    return getImages(arrayList, 'TERRAFORM')
  }

  function getMathEquationImages(arrayList) {
    return getImages(arrayList, 'MATHEQUATION')
  }

  function getPlayBookGrapherImages(arrayList) {
    return getImages(arrayList, 'PLAYBOOKGRAPHER')
  }

  function getInventoryGrapherImages(arrayList) {
    return getImages(arrayList, 'INVENTORYGRAPHER')
  }

  function getMindMapImages(arrayList) {
    return getImages(arrayList, 'MINDMAP')
  }

  function renderAccordionContent(exampleData, getImageFunction) {
    return (
      exampleData.length > 0 &&
      exampleData[0]['Getting Started'].map((item, idx) => (
        <React.Fragment key={idx}>
          <CAccordion activeItemKey={1}>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>{Object.keys(item)}</CAccordionHeader>
              <CAccordionBody style={{ backgroundColor: COLORS.PRIMARY_LIGHT }}>
                <div className="row justify-content-center align-items-center">
                  {getImageFunction(item)}
                </div>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </React.Fragment>
      ))
    )
  }

  return (
    <div>
      {selectedMode === CONSTANTS.WEBSEQUENCE &&
        websequenceExample.map((item, idx) => (
          <React.Fragment key={idx}>
            {Object.keys(websequenceExample[idx])[0] !== 'Getting Started' && (
              <CAccordion activeItemKey={1}>
                <CAccordionItem itemKey={idx + 1}>
                  <CAccordionHeader>{Object.keys(websequenceExample[idx])}</CAccordionHeader>
                  <CAccordionBody className="p-0">
                    {getNestedItems(idx, websequenceExample)}
                  </CAccordionBody>
                </CAccordionItem>
              </CAccordion>
            )}
          </React.Fragment>
        ))}

      {selectedMode === CONSTANTS.ARCHITECTURE_DIAGRAM && (
        <CAccordion activeItemKey={1}>
          <CAccordionItem>
            <CAccordionHeader>My Custom Icons</CAccordionHeader>
            <CAccordionBody style={{ backgroundColor: COLORS.PRIMARY_LIGHT }}>
              <div className="row justify-content-center align-items-center">
                {myIconsList.length > 0 ? (
                  myIconsList.map((item, idx) => getInnerItems(item, true))
                ) : (
                  <p style={{ color: COLORS.WHITE }}>No icons to display</p>
                )}
              </div>
              <p style={{ color: COLORS.WHITE }}> Upload Limit: {uploadLimit}</p>
              <Button className="icon-button" onClick={handleUploadButtonClick}>
                Upload
              </Button>
              <Button
                className="icon-button"
                style={{ marginLeft: '5px' }}
                onClick={() => getMyIcons(true)}
              >
                <ReplayIcon fontSize="small" />
              </Button>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      )}

      {selectedMode === CONSTANTS.ARCHITECTURE_DIAGRAM &&
        items.map((item, idx) => (
          <React.Fragment key={idx}>
            <CAccordion activeItemKey={1}>
              <CAccordionItem itemKey={idx + 1}>
                <CAccordionHeader>{Object.keys(items[idx])}</CAccordionHeader>
                <CAccordionBody className="p-0">{getNestedItems(idx)}</CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </React.Fragment>
        ))}

      {selectedMode === CONSTANTS.WEBSEQUENCE &&
        renderAccordionContent(websequenceExample, getWebSeqImages)}

      {selectedMode === CONSTANTS.FLOW_CHART &&
        renderAccordionContent(flowchartExample, getFlowchartImages)}

      {selectedMode === CONSTANTS.SWAGGER &&
        renderAccordionContent(swaggerExample, getSwaggerImages)}

      {selectedMode === CONSTANTS.JSON && renderAccordionContent(jsonUmlExample, getJSONImages)}

      {selectedMode === CONSTANTS.YAML && renderAccordionContent(yamlUmlExample, getYamlImages)}

      
      {selectedMode === CONSTANTS.ASCII && renderAccordionContent(asciiExample, getAsciiImage)}

      {selectedMode === CONSTANTS.CLASS_DIAGRAM &&
        renderAccordionContent(classDiagramExample, getClassDiagramImages)}

      {selectedMode === CONSTANTS.BRAINSTORM &&
        renderAccordionContent(brainStormExample, getBrainstormImages)}

      {selectedMode === CONSTANTS.PROTOBUF &&
        renderAccordionContent(protobufExample, getProtobufImages)}

      {selectedMode === CONSTANTS.DOCKERCOMPOSE &&
        renderAccordionContent(dockerComposeExample, getDockerComposeImages)}

      {selectedMode === CONSTANTS.AVROSCHEMA &&
        renderAccordionContent(avroSchemaExample, getAvroSchemaImages)}

      {selectedMode === CONSTANTS.D2 && renderAccordionContent(d2Example, getD2Images)}

      {selectedMode === CONSTANTS.ARCHGO && renderAccordionContent(archGoExample, getArchGoImages)}

      {selectedMode === CONSTANTS.TERRAFORM &&
        renderAccordionContent(terraformExample, getTerraformImages)}

      {selectedMode === CONSTANTS.MATHEQUATION &&
        renderAccordionContent(mathEquationExample, getMathEquationImages)}

      {selectedMode === CONSTANTS.PLAYBOOK_GRAPHER &&
        renderAccordionContent(playBookGrapherExample, getPlayBookGrapherImages)}

      {selectedMode === CONSTANTS.INVENTORY_GRAPHER &&
        renderAccordionContent(inventoryGrapherExample, getInventoryGrapherImages)}
      
      {selectedMode === CONSTANTS.MINDMAP &&
        renderAccordionContent(mindmapExample, getMindMapImages)}
    </div>
  )
}
