import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Autocomplete, TextField, Box, Divider } from '@mui/material';

const ShareModal = ({
  visibleShare,
  setVisibleShare,
  setFilename,
  handleSelectedTeam,
  selectedEmail,
  teamName,
  setTeamName,
  shareCodeToTeam,
  filename,
  direction,
  diagramName,
  teamList,
  tag,
  setTag,
  generatedLink,
  CopyFunction,
  handleShareDiagram,
  setGeneratedLink,
}) => {
  const [selectedOption, setSelectedOption] = useState({
    label: "Share File",
    value: "file",
  });
  const [selectedTeam, setSelectedTeam] = useState('');

  const handleTagChange = (newTag) => {
    const newEmails = newTag.split(/[, \n]+/).map((tag) => tag.trim());
    setTag(newEmails);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const cursorPosition = e.target.selectionStart;
      const newValue = e.target.value;

      if (newValue[cursorPosition - 2] === ',' && newValue[cursorPosition - 1] === ' ') {
        e.preventDefault();
        const updatedValue = newValue.slice(0, cursorPosition - 2) + newValue.slice(cursorPosition);
        handleTagChange(updatedValue);
      }
    }
  };

  const options = [
    { label: "Share File", value: "file" },
    { label: "Share Diagram Link", value: "link" }
  ];

  useEffect(() => {
    if (!visibleShare) {
      setSelectedTeam('');
      setGeneratedLink('');
    } else {
      const DEFAULTFILENAME = 'DEFAULTFILENAME';
      const selectedFile = localStorage.getItem('SELECTEDFILE');
      setFilename(selectedFile && selectedFile !== DEFAULTFILENAME ? selectedFile : '');
    }
  }, [visibleShare, setFilename, setGeneratedLink]);

  const clearGeneratedLink = () => {
    setGeneratedLink('');
  };

  const handleShare = () => {
    try {
      shareCodeToTeam({
        fileName: filename,
        orientation: direction,
        diagramName: diagramName,
        emailList: selectedEmail.length > 0 ? selectedEmail : tag,
        name: teamName,
        isExistingTeam: selectedEmail.length > 0 ? 1 : 0,
      });
      clearGeneratedLink();
    } catch (error) {
      console.error("Error sharing code:", error);
      alert("An error occurred while sharing the code. Please try again.");
    }
  };

  return (
    <CModal visible={visibleShare} onClose={() => setVisibleShare(false)}>
      <Box sx={{ display: 'flex', paddingLeft: '15px', paddingTop: '15px' }}>
        <CModalTitle>Share</CModalTitle>
      </Box>
      <CModalBody>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
          <Autocomplete
            value={selectedOption}
            onChange={(e, newValue) => setSelectedOption(newValue)}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
            disableClearable
          />


          <Box sx={{ display: 'flex', paddingBottom: '5px' }}>
            <CModalTitle>Details</CModalTitle>
          </Box>
          {selectedOption.value === 'file' && (
            <>
              <TextField
                variant="outlined"
                size="small"
                placeholder="File Name"
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
                InputProps={{
                  readOnly: true,
                }}
              />

              <Autocomplete
                disableClearable
                options={teamList || []}
                getOptionLabel={(option) => option.name || ''}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedTeam(newValue.teamId);
                    handleSelectedTeam(newValue.teamId);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select a team or Create new team"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} style={{ cursor: 'pointer' }}>
                    {option.name}
                  </li>
                )}
                noOptionsText="No Team"
                style={{ width: '100%' }}
              />
              {selectedEmail && selectedEmail.length > 0 && (
                <p>Team Emails: {selectedEmail.join(', ')}</p>
              )}
              <p style={{ paddingTop: "5px" }}>Or</p>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Team Name"
                onChange={(e) => setTeamName(e.target.value)}
              />
              <TextField
                variant="outlined"
                size="small"
                placeholder="Enter multiple emails separated by comma/space"
                value={tag.join(', ')}
                onChange={(e) => handleTagChange(e.target.value)}
                onKeyDown={handleInputKeyDown}
              />
            </>
          )}
          {selectedOption.value === 'link' && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className="icon-button"
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleShareDiagram();
                    clearGeneratedLink();
                  }}
                >
                  Generate Link
                </Button>
                <Tooltip title="Copy">
                  <Button className="icon-button" size="small" onClick={CopyFunction}>
                    <ContentCopyIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Box>
              <TextField
                variant="outlined"
                size="small"
                value={generatedLink}
                placeholder="Diagram Link"
                aria-label="Diagram Link"
              />
            </>
          )}
        </Box>
      </CModalBody>
      <Box display="flex" justifyContent="flex-end">
        {selectedOption.value === 'file' && (
          <Button className="icon-button " onClick={handleShare} style={{ marginRight: '14px', marginBottom: '10px' }} >
            Share
          </Button>
        )}

      </Box>

    </CModal>
  );
};

export default ShareModal;
