export const CONSTANTS = {
  WEBSEQUENCE: 'WebSequence',
  ARCHITECTURE_DIAGRAM: 'Architecture',
  FLOW_CHART: 'FlowChart',
  CLASS_DIAGRAM: 'ClassDiagram',
  SWAGGER: 'uml',
  JSON: 'json',
  YAML: 'yaml',
  GANTT_CHART: 'GanttChart',
  DEFAULTFILENAME: 'DEFAULTFILENAME',
  BRAINSTORM: 'BrainStorm',
  PROTOBUF: 'Protobuf',
  AVROSCHEMA: 'AvroSchema',
  DOCKERCOMPOSE: 'DockerCompose',
  MINDMAP: 'Mindmap',
  ARCHGO: 'ArchGo',
  D2: 'D2',
  TERRAFORM: 'Terraform',
  MATHEQUATION: 'MathEquation',
  PLAYBOOK_GRAPHER: 'PlaybookGrapher',
  INVENTORY_GRAPHER: 'InventoryGrapher',
  ASCII: 'ascii'
}
