import { SequenceDiagram } from "svg-sequence-diagram";


export async function generateWebSeq(code, setSnackbarMessage, setSnackbarSeverity, setOpenSnackbar, setOpenSnackbarDiagram) {
    try {
        const myDiagram = new SequenceDiagram(code);
        const { url, latest } = await myDiagram.getSVG();
        const response = await fetch(url);
        const svgText = await response.text();

        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(svgText, "image/svg+xml");

        // Make modifications to the SVG content
        svgDocument.querySelectorAll("text").forEach((textElement) => {
            textElement.setAttribute("fill", "Gray");
        });

        // Set background color for the SVG
        svgDocument.documentElement.setAttribute("style", "background-color: #ffffff;");

        // Serialize the modified SVG back to a string
        const finalSVG = new XMLSerializer().serializeToString(svgDocument);

        localStorage.setItem("LASTGENERATEDIMAGE_WEB", `data:image/svg+xml,${encodeURIComponent(finalSVG)}`);
        return `data:image/svg+xml,${encodeURIComponent(finalSVG)}`;
    } catch (error) {
        
        setOpenSnackbar(true)
        setSnackbarMessage('An error occurred. '+ error)
        setSnackbarSeverity('error')
        setOpenSnackbarDiagram(false)
        return null;
    }
}


export const getWebSeqPNG = async (imageUrl) => {
    return new Promise(async (resolve, reject) => {
        try {
            // Load the image asynchronously
            const response = await fetch(imageUrl);
            const blob = await response.blob();

            // Convert the image to a data URL
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(",")[1];

                // Create a new canvas with a white background
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                const img = new Image();
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height + 400;
                    // Draw white background
                    ctx.fillStyle = "#ffffff";
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    // Draw the image on the white background
                    ctx.drawImage(img, 0, 0);

                    // Convert the canvas to a data URL
                    const watermarkedBase64 = canvas.toDataURL("image/png").split(",")[1];
                    resolve(`data:image/png;base64,${watermarkedBase64}`);
                };

                img.src = `data:image/png;base64,${base64String}`;
            };

            reader.readAsDataURL(blob);
        } catch (error) {
            console.error("Error caching image:", error);
            reject(null);
        }
    });
};


